<template>
  <CustomImage
    :image-id="dynamicImage.id"
    :image-src="dynamicImage.src"
    :media="media"
    :aspect-ratio="dynamicImage.ratio"
    :width="dynamicImage.sizes[0]"
    :height="dynamicImage.sizes[1]"
    :alt="alt"
    :loading="loading"
    :ext="ext"
  />
</template>

<script>
import CustomImage from '~/components/elements/CustomImage';
export default {
  name: 'DynamicImage',
  components: {CustomImage},
  props: {
    image: {
      type: Object,
      required: true,
    },
    imageSrc: {
      type: String,
      default: '',
    },
    media: {
      type: [Array, null],
      default: null,
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
    sizes: {
      type: Object,
      default() {
        return {}
      },
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    ext: {
      type: String,
      default: '',
    },
  },
  data() {
    const defaultRatio = {
      mobile: '97.78%',
      tablet: '27.1%',
      pc: '27.1%',
    }

    const defaultSizes = {
      mobile: {
        width: 360,
        height: 352,
      },
      tablet: {
        width: 1200,
        height: 325,
      },
      pc: {
        width: 1200,
        height: 325,
      },
    }

    return {
      imageRatio: {
        ...defaultRatio,
        ...this.ratio,
      },
      imageSizes: {
        ...defaultSizes,
        ...this.sizes,
      },
    }
  },
  computed: {
    dynamicImage() {
      return {
        src: this.imageSrc,
        id: this.image?.id || '',
        sizes: [this.imageSizes.pc.width, this.imageSizes.pc.height],
        ratio: this.imageRatio.pc,
      }
    },
    alt() {
      return this.image?.caption || ''
    },
  },
}
</script>
