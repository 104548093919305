<template>
  <div class="banner-grid">
    <div v-if="title" class="banner-grid__title-wrapper mb_m">
      <div class="banner-grid__title font font_title-xl font_bold font_uppercase">
        {{ title }}
      </div>
    </div>
    <div class="banner-grid__body" :style="bannerRow">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GridWrapper',
  props: {
    title: {
      type: String,
      default: '',
    },
    row: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bannerRow() {
      return {
        '--banner-row-pc': this.row.pc,
        '--banner-row-tablet': this.row.tablet,
        '--banner-row-tablet-sm': this.row.tabletSm,
        '--banner-row-mobile': this.row.mobile,
      }
    },
  },
}
</script>

