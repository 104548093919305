<template>
  <div>
    <GridWrapper :title="title" :row="row">
      <div
        v-for="(item, index) in images"
        :key="index"
        class="banner-grid__item"
      >
        <div @click="onClickBanner(item, index)">
          <BannerGridItemWrapper
            :item="item"
            :padding="padding"
            :ratio="ratio"
            :sizes="sizes"
            :shadow="shadow"
            :round="round"
            :loading="loading"
          />
        </div>
      </div>
    </GridWrapper>
    <CoolLightBox
      v-if="galleryItems.length"
      :items="galleryItems"
      :index="imageOpenIndex"
      @close="imageOpenIndex = null"
    >
    </CoolLightBox>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import GridWrapper from '~/components/elements/GridWrapper';
import BannerGridItemWrapper from '~/components/elements/BannerGridItemWrapper';
import {generateYoutubeVideoSourcePath} from '~/plugins/helpers/file';

export default {
  name: 'BannerGrid',
  components: {BannerGridItemWrapper, GridWrapper, CoolLightBox},
  props: {
    title: {
      type: String,
      default: '',
    },
    images: {
      type: Array,
      required: true,
    },
    ratio: {
      type: Object,
      required: true,
    },
    sizes: {
      type: Object,
      required: false,
    },
    row: {
      type: Object,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageOpenIndex: null,
    }
  },
  computed: {
    galleryItems() {
      const slides = []
      this.images.forEach(item => {
        if (item?.youtube) {
          slides.push({ src: generateYoutubeVideoSourcePath(item.youtube), youtube: item.youtube  })
        }
      })

      return slides
    },

  },
  methods: {
    onClickBanner(item) {
      const { youtube } = item

      if (!youtube) return

      const id = this.getCurrentGalleryId(youtube)

      if (id !== -1) {
        this.imageOpenIndex = id
      }
    },
    getCurrentGalleryId(youtube) {
      return this.galleryItems.findIndex(item => item.youtube === youtube)
    },
  },

}
</script>

